// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("/home/forge/bulldozer.bulldev.se/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-contact-js": () => import("/home/forge/bulldozer.bulldev.se/src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-archive-js": () => import("/home/forge/bulldozer.bulldev.se/src/templates/archive.js" /* webpackChunkName: "component---src-templates-archive-js" */),
  "component---src-pages-index-js": () => import("/home/forge/bulldozer.bulldev.se/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-case-js": () => import("/home/forge/bulldozer.bulldev.se/src/templates/case.js" /* webpackChunkName: "component---src-templates-case-js" */),
  "component---src-templates-post-js": () => import("/home/forge/bulldozer.bulldev.se/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("/home/forge/bulldozer.bulldev.se/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

