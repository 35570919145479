
import React, { Component } from "react"
import Bg from './bg.js';
import bgSrc from "../../assets/images/bg/rosa_bla.svg"

class Background extends Component {
    constructor() {
        super();
        this.app = null;
        this.svg = null;
    }
    componentDidMount() {
        if (!document.getElementById('background')) {
            this.bootstrap();
        }
    }

    bootstrap = () => {
        this.svg = new Image();

        this.svg.onload = () => {
            this.app = new Bg(this.svg, '.stage');
        }

        this.svg.src = bgSrc;
    }

    render() {

        return (
            <div>
                {this.props.children}
            </div>
        )
    }
}


export default Background
