/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// import wrapWithProvider from "./wrap-with-provider"
// export const wrapRootElement = wrapWithProvider

import React from "react";
import Transition from "./src/components/transition";
import { Provider } from 'react-redux';
import createStore from './src/state/createStore';
import Background from "./src/components/background/background"

const store = createStore();

const transitionDelay = 125
export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    if (location.action === 'PUSH') {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
    } else {
        const savedPosition = getSavedScrollPosition(location)
        window.setTimeout(
            () => window.scrollTo(...(savedPosition || [0, 0])),
            transitionDelay
        )
    }
    return false
}
export const wrapPageElement = ({ element, props }) => {
    return (
        <Provider store={store}>
            <Background >
                <Transition {...props}>
                    {element}
                </Transition>
            </Background>
        </Provider>
    )
};