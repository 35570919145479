export const vertex = `
  precision mediump float;
  varying vec2 vUv;
  uniform float time;

  void main() {
    vUv  = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

export const fragment = `
  precision mediump float;

  uniform float time;
  uniform float mouseX;
  uniform float mouseY;
  uniform sampler2D texture;
  varying vec2 vUv;

  void main() {
    float d = -distance(vec2(mouseX,mouseY), gl_FragCoord.xy);
    float r = dot(gl_FragCoord.xy, vec2(0.01,0.01))/d;
    vec2 tex = vec2(vUv.x + r, vUv.y + r);
    gl_FragColor = vec4(texture2D(texture, tex).rgb, 1.0);
  }
`;

export default { vertex, fragment };
