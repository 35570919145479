import React from "react";
import posed, { PoseGroup } from "react-pose";

const timeout = 100;

class Transition extends React.PureComponent {
    render() {
        const { children, location } = this.props;
        const RoutesContainer = posed.div({
            enter: {
                opacity: 1,
                // filter: "blur(0px)",
                // y: 0,
                // x: 0,
                delay: timeout,
                delayChildren: timeout,
                beforeChildren: 0,
                transition: {
                    duration: 300
                }
            },
            exit: {
                opacity: 0,
                // x: '100vw',
                beforeChildren: 0,
                transition: {
                    duration: 50
                }
                // filter: "blur(20px)",
                // y: 30
            }
        });

        return (
        <PoseGroup animateOnMount={true}>
            <RoutesContainer key={location.pathname}>
                {children}
            </RoutesContainer>
        </PoseGroup>
        );
    }
}

export default Transition;