import { cover } from './helpers.js';
import shaders from './shaders.js';
import * as THREE from 'three';
import { TweenMax } from "gsap/TweenMax";
import _ from "lodash";

console.clear();

const APP_CONFIG = {
    debug: false,
    gridDebugSize: {
        x: 10,
        y: 10
    },
    axisHelperSize: 10
}

class Bg {
    constructor(svg, stageContainer) {
        this.container = document.querySelector(stageContainer);
        _.bindAll(this, 'animate', 'onResize', 'onMouseMove', 'onTouchMove');
        this.time = 0;
        this.planeHeight = 50;
        this.ratio = window.innerWidth / window.innerHeight;
        // this.ratio = this.container.offsetWidth / this.container.offsetHeight;
        this.planeWidth = this.planeHeight * this.ratio;

        //SET-UP CAMERA
        this.cameraOpts = {
            // aspect: this.container.offsetWidth / this.container.offsetHeight,
            aspect: window.innerWidth / window.innerHeight,
            near: 0.1,
            far: 10000,
            z: this.planeHeight
        }

        let fov = 2 * Math.atan(this.planeHeight / (2 * this.cameraOpts.z)) * (180 / Math.PI);

        this.camera = new THREE.PerspectiveCamera(fov, this.cameraOpts.aspect, this.cameraOpts.near, this.cameraOpts.far);
        this.camera.position.z = this.cameraOpts.z;

        //SET-UP STAGE
        this.stage = new THREE.Scene();
        this.stage.add(this.camera);

        //SET-UP RENDERER
        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        // this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.domElement.id = 'background';
        this.start(svg);
    }

    start(svg) {

        if (APP_CONFIG.debug) {
            this.debug();
        }

        let texture = new THREE.Texture(this.createCanvas(svg));
        texture.needsUpdate = true;

        let planeGeometry = new THREE.PlaneGeometry(this.planeWidth, this.planeHeight, 0, 0);
        this.uniforms = {
            texture: {
                type: 't',
                value: texture
            },
            time: {
                type: "f",
                value: this.time
            },
            mouseX: {
                type: "f",
                value: 0
            },
            mouseY: {
                type: "f",
                value: 0
            }
        }

        let planeMaterial = new THREE.ShaderMaterial({
            uniforms: this.uniforms,
            vertexShader: shaders.vertex,
            fragmentShader: shaders.fragment,
            wireframe: false,
            wireframeLinewidth: 2,
            transparent: true
        });

        this.plane = new THREE.Mesh(planeGeometry, planeMaterial);
        this.stage.add(this.plane);

    
        this.container.appendChild(this.renderer.domElement);
        TweenMax.ticker.addEventListener('tick', this.animate);
        //ADD EVENTS LISTENER
        this.listen();
    }

    createCanvas(svg) {
        this.canvas = document.createElement('canvas');
        // this.canvas.height = this.container.offsetHeight;
        this.canvas.height = window.innerHeight;
        this.canvas.width = this.canvas.height * this.ratio;
        let context = this.canvas.getContext('2d');

        const {
            offsetX,
            offsetY,
            width,
            height
        } = cover(this.canvas.width, this.canvas.height, svg.width, svg.height);

        context.drawImage(svg, offsetX, offsetY, width, height);

        return this.canvas;
    }

    debug() {
        let gridHelper = new THREE.GridHelper(APP_CONFIG.gridDebugSize.x, APP_CONFIG.gridDebugSize.y);
        this.stage.add(gridHelper);
        let axisHelper = new THREE.AxisHelper(APP_CONFIG.axisHelperSize);
        this.stage.add(axisHelper);
    }

    listen() {
        // this.container = document.querySelector('.stage');
        let lazyLayout = _.debounce(this.onResize, 300);
        window.addEventListener('resize', lazyLayout);
        this.container.addEventListener('mousemove', this.onMouseMove);
        // window.addEventListener('touchmove', this.onTouchMove);
    }

    onResize(e) {
        // this.container = document.querySelector('.stage');
        // this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.camera.updateProjectionMatrix();
    }

    onMouseMove(e) {
        // this.container = document.querySelector('.stage');
        // console.log(e.clientX);
        // console.log((this.container.offsetHeight + window.innerHeight) - e.clientY);
        this.mousePos = {
            x: e.clientX,
            y: e.clientY
        }
    }

    onTouchMove(e) {
        const touch = e.touches[0];

        if (!touch) { return; }

        this.mousePos = {
            x: touch.pageX,
            y: touch.pageY
        }
    }

    animate() {
        // this.container = document.querySelector('.stage');
        if (this.mousePos) {
            this.uniforms.mouseX.value = this.mousePos.x;
            // this.uniforms.mouseY.value = this.container.offsetHeight - this.mousePos.y;
            this.uniforms.mouseY.value = window.innerHeight - this.mousePos.y;
        }

        this.renderer.render(this.stage, this.camera);
        this.uniforms.time.value += 0.1;

    }

}

export default Bg;
